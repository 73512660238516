/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b6d9ea20-5b1b-49f8-a48a-0f204c3773c1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XNl0EcpPl",
    "aws_user_pools_web_client_id": "j3qhvl34di3o1ao82tic0vl57",
    "oauth": {}
};


export default awsmobile;
